var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.model)?_c('v-stepper',{attrs:{"vertical":false},model:{value:(_vm.step),callback:function ($$v) {_vm.step=$$v},expression:"step"}},[_c('v-stepper-header',[_vm._l((_vm.model.datasets),function(dataset,index){return [_c('v-stepper-step',{key:dataset.code + index,attrs:{"complete":_vm.step > dataset.order,"step":dataset.order},on:{"click":function($event){return _vm.goToThisStep(dataset.order)}}},[_vm._v(" "+_vm._s(dataset.title)+" "),_c('small',[_vm._v(_vm._s(dataset.subtitle))])])]})],2),_c('v-stepper-items',[_vm._l((_vm.model.datasets),function(dataset,index){return [_c('v-stepper-content',{key:dataset.code + index + 'content',attrs:{"step":dataset.order}},[_c('v-row',{staticStyle:{"min-height":"700px"}},[_c('v-col',{attrs:{"sm":"3"}},[(
                _vm.activeDataset &&
                  _vm.activeDataset.code === dataset.code &&
                  _vm.model &&
                  dataset.axis === 'build'
              )?_c('model-selector',{attrs:{"input":{ dataset: dataset, index: index, model: _vm.model }},on:{"next":_vm.nextDataset,"updated":_vm.updated,"build":_vm.build}}):_vm._e(),(
                _vm.activeDataset &&
                  _vm.activeDataset.code === dataset.code &&
                  dataset.axis !== 'build'
              )?_c('dataset-selector',{attrs:{"input":{ dataset: dataset, index: index }},on:{"next":_vm.nextDataset,"updated":_vm.updated}}):_vm._e()],1),(_vm.activeDataset)?_c('v-col',{attrs:{"sm":"9"}},[(_vm.activeDataset && _vm.activeDataset.code === dataset.code)?_c('chart',{key:_vm.activeDataset.uiKey + _vm.activeDataset.table_name,attrs:{"dataset":dataset}}):_vm._e()],1):_vm._e()],1)],1)]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }