<template>
  <div style="height: 100%; width: 100%">
    <loading
      v-show="loading || loadingInState"
      :message="loadingMessage"
    />
    <v-layout
      v-show="!loading"
      row
      style="height: 100%; width: 100%; padding: 10px; padding-right: 20px"
    >
      <!-- <v-col
        xs12
        v-show="loading"
        style="min-height:100vh; width: 100%; padding: 10px; padding-left: 30px;"
      >
        <loading :message="loadingMessage" />
      </v-col> -->
      <v-col
        v-if="scores"
        cols="12"
        xs="12"
      >
        <v-alert
          v-if="scores.strength === 'high'"
          :value="true"
          type="info"
        >
          {{ scores.message }}
        </v-alert>
        <v-alert
          v-if="scores.strength === 'higmediumh'"
          :value="true"
          type="warning"
        >
          {{ scores.message }}
        </v-alert>
        <v-alert
          v-if="scores.strength === 'low'"
          :value="true"
          type="error"
        >
          {{ scores.message }}
        </v-alert>
      </v-col>
      <v-col
        cols="12"
        xs="12"
        style="
          min-height: 100vh;
          width: 100%;
          padding: 10px;
          padding-left: 30px;
        "
      >
        <v-layout row>
          <v-row v-if="activeDataset">
            <v-autocomplete
              v-if="locations.length && display === 'forecast'"
              v-model="location"
              deletable-chips
              small-chips
              :items="locations"
              item-text="Location"
              item-value="Location"
              @change="setScores"
            />
            <v-spacer />
            <v-col
              v-if="activeDataset"
              style="margin-bottom: 10px; text-align: right"
            >
              <v-btn-toggle v-model="display">
                <v-btn
                  v-if="activeDataset && activeDataset.axis === 'build'"
                  text
                  value="forecast"
                >
                  Results
                </v-btn>
                <!-- <v-btn
                  v-if="activeDataset && activeDataset.axis === 'build'"
                  text
                  value="date-motion"
                >
                  Motion Chart
                </v-btn> -->
                <v-btn
                  v-if="activeDataset && activeDataset.axis === 'build'"
                  text
                  value="feature-importance"
                >
                  Feature Importance
                </v-btn>
                <v-btn
                  v-if="activeDataset && activeDataset.axis === 'build'"
                  text
                  @click="download"
                >
                  Download
                </v-btn>
                <v-btn
                  v-if="activeDataset && activeDataset.axis !== 'build'"
                  text
                  value="annotated"
                >
                  Time Series Line Chart
                </v-btn>
                <v-btn
                  v-if="activeDataset && activeDataset.axis !== 'build'"
                  text
                  value="map"
                >
                  Map View
                </v-btn>
                <v-btn
                  v-if="activeDataset && activeDataset.axis !== 'build'"
                  text
                  value="heatmap"
                >
                  Time Series vs Locations Heatmap
                </v-btn>
                <v-btn
                  v-if="
                    activeDataset &&
                      activeDataset.axis !== 'build' &&
                      showCluster
                  "
                  text
                  value="cluster"
                >
                  Cluster Locations
                </v-btn>
                <v-btn
                  v-if="activeDataset && activeDataset.axis !== 'build'"
                  text
                  value="category"
                >
                  Categories
                </v-btn>
                <v-btn
                  v-if="activeDataset && activeDataset.axis !== 'build'"
                  text
                  value="motion-series"
                >
                  Motion Series
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-layout>
        <forecast-chart
          v-if="
            display == 'forecast' &&
              activeDataset &&
              modelInState &&
              featureImportanceId
          "
          :key="activeDataset.uiKey + location + featureImportanceId"
          :dataset="activeDataset"
          :model="modelInState"
          :location="location"
          style="height: 450px; width: 100%"
        />
        <date-motion-chart
          v-if="
            display == 'date-motion' && activeDataset && featureImportanceId
          "
          :key="activeDataset.uiKey + featureImportanceId"
          :dataset="activeDataset"
          :model="modelInState"
          :payload="payload"
          style="height: 700px; width: 100%"
        />
        <importance-chart
          v-if="
            display == 'feature-importance' &&
              activeDataset &&
              featureImportanceId
          "
          :key="activeDataset.uiKey + featureImportanceId"
          :dataset="activeDataset"
          :payload="payload"
          style="height: 700px; width: 100%"
        />
        <map-chart
          v-if="display == 'map' && activeDataset"
          :key="activeDataset.uiKey"
          :dataset="activeDataset"
          style="height: 700px; width: 100%"
        />
        <heatmap-chart
          v-if="display == 'heatmap' && activeDataset"
          :key="activeDataset.uiKey"
          :dataset="activeDataset"
          style="height: 900px; width: 100%"
        />
        <category-chart
          v-if="display == 'category' && activeDataset"
          :key="activeDataset.uiKey"
          :dataset="activeDataset"
          style="height: 900px; width: 100%"
        />
        <cluster-chart
          v-if="display == 'cluster' && activeDataset && showCluster"
          :key="activeDataset.uiKey"
          :dataset="activeDataset"
          :model="modelInState"
          style="height: 900px; width: 100%"
        />
        <annotated-chart
          v-if="display == 'annotated' && activeDataset"
          :key="activeDataset.uiKey"
          :dataset="activeDataset"
          style="height: 450px; width: 100%"
        />
        <motion-series-chart
          v-if="display == 'motion-series' && activeDataset"
          :key="activeDataset.uiKey"
          :dataset="activeDataset"
          style="height: 750px; width: 100%"
        />
        <!-- <heatmap-chart
          style="height: 95%; width: 100%"
          v-if="display == 'heatmap'"
        />
        <wordcloud-chart
          style="height: 95%; width: 100%"
          v-if="display == 'wordcloud'"
        />
        <cluster-chart
          style="height: 95%; width: 100%"
          v-if="display == 'cluster'"
        /> -->
      </v-col>
    </v-layout>
  </div>
</template>

<script>
/* eslint-disable import/no-unresolved */
import { createNamespacedHelpers } from 'vuex';
import { API_URL } from '@/config';
import ApiService from '../../services/api.service';
import { createTrainingQuery } from '../../lib/query-creator';

const AnnotatedChart = () => import('@/components/chart/annotated.vue');
const ForecastChart = () => import('@/components/chart/forecast.vue');
const MotionSeriesChart = () => import('@/components/chart/motion/series-chart.vue');
const HeatmapChart = () => import('@/components/chart/heatmap/chart.vue');
const ClusterChart = () => import('@/components/chart/cluster/chart.vue');
const MapChart = () => import('@/components/chart/map/chart.vue');
const CategoryChart = () => import('@/components/chart/category/chart.vue');
const ImportanceChart = () => import('@/components/chart/importance/chart.vue');
const DateMotionChart = () => import('@/components/chart/date-motion/chart.vue');
const Loading = () => import('@/components/loading.vue');
// const ForecastChart = () => import('@/components/charts/orr/forecast.vue');
// const HeatmapChart = () => import('@/components/charts/orr/heatmap.vue');
// const WordcloudChart = () => import('@/components/charts/orr/wordcloud.vue');
// const ClusterChart = () => import('@/components/charts/orr/cluster.vue');
// const OrrSlider = () => import('@/components/charts/orr/selectors.vue');

// const Filters = () => import('@/components/charts/orr/filters.vue');
const modelNamespace = createNamespacedHelpers('model');

function downloadURI(path, name) {
  const link = document.createElement('a');
  link.setAttribute('download', name);
  link.href = API_URL + path;
  document.body.appendChild(link);
  link.click();
  link.remove();
}

export default {
  components: {
    AnnotatedChart,
    MotionSeriesChart,
    ForecastChart,
    ClusterChart,
    HeatmapChart,
    MapChart,
    CategoryChart,
    ImportanceChart,
    DateMotionChart,
    Loading,
    // ForecastChart,
    // HeatmapChart,
    // WordcloudChart,
    // ClusterChart,
    // OrrSlider,
    // Filters,
  },
  props: ['dataset'],
  data() {
    return {
      // display: 'annotated',
      showCluster: false,
      activeDataset: null,
      loading: true,
      loadingMessage: 'Loading',
      locations: [],
      location: null,
      featureImportanceId: null,
      payload: null,
      scores: null,
    };
  },
  computed: {
    ...modelNamespace.mapState({
      // activeDataset: (state) => state.dataset,
      modelInState: (state) => state.model,
      displayInState: (state) => state.display,
      lastCode: (state) => state.lastCode,
      // step: (state) => state.step,
    }),
    loadingInState: {
      get() {
        return this.$store.state.loading;
      },
    },
    display: {
      get() {
        return this.displayInState;
      },
      set(display) {
        this.mSetDisplay(display);
      },
    },
  },
  beforeMount() {},
  beforeCreate() {},
  mounted() {
    this.setShowCluster();
    if (this.dataset) {
      this.activeDataset = { ...this.dataset };
      if (this.lastCode !== this.dataset.code) {
        if (this.activeDataset.axis === 'build') {
          this.mSetDisplay('forecast');
        } else {
          this.mSetDisplay('annotated');
        }
        this.mSetLastCode(this.dataset.code);
      }
      this.initiateBuild();
    }
  },
  methods: {
    ...modelNamespace.mapActions(['aCreateModel']),
    ...modelNamespace.mapMutations(['mSetDisplay', 'mSetLastCode']),
    async initiateBuild() {
      if (this.dataset.axis !== 'build') {
        this.loading = false;
        this.loadFeatureImportance();
        return false;
      }
      this.loading = true;
      try {
        await this.trainModelWithoutFile();
        const model = await this.getUnchangedModel();
        console.log('model', model);
        if (!model) {
          // Model has changed so prepare again
          await this.build(this.dataset);
        }
      } catch (error) {
        console.log('error', error);
      }
      this.setScores();
      this.loadFeatureImportance();
      this.loadLocations();
      this.loading = false;
    },
    getChangeId(dataset) {
      const model = this.modelInState;
      const query = createTrainingQuery(this.modelInState);
      model.sql = query.sql;
      const independent_variable_names_selected_by_user = this.dataset.independent_variable_names_selected_by_user;
      const kChanges = {
        model_architecture: dataset.algorithm,
        independent_variable_names_selected_by_user,
        model_name: dataset.name,
        type: dataset.algorithm,
        description: model.description,
        model_code: model.model_code,
        start_date: dataset.start_date,
        end_date: dataset.end_date,
        start_test_date: dataset.start_test_date,
        sql: query.sql,
      };
      return JSON.stringify(kChanges);
    },
    preparePayload(dataset) {
      const model = this.modelInState;
      const query = createTrainingQuery(this.modelInState);
      model.sql = query.sql;
      const independent_variable_names_selected_by_user = this.dataset.independent_variable_names_selected_by_user;

      const kChanges = {
        model_architecture: dataset.algorithm,
        independent_variable_names_selected_by_user,
        model_name: dataset.name,
        type: dataset.algorithm,
        description: model.description,
        model_code: model.model_code,
        start_date: dataset.start_date,
        end_date: dataset.end_date,
        start_test_date: dataset.start_test_date,
        sql: query.sql,
      };
      model.change_id = this.getChangeId(dataset);
      const payload = { ...kChanges };
      payload.ui_json = JSON.stringify(model);
      return payload;
    },
    loadFeatureImportance() {
      this.payload = this.preparePayload(this.dataset);
      this.featureImportanceId = this.getChangeId(this.dataset);
      // const response = await ApiService.post('/feature_importance/', payload);
    },
    async trainModelWithoutFile() {
      const payload = this.preparePayload(this.dataset);
      await ApiService.post('/re_train/', payload);
    },
    async getFeatureImportance() {
      const payload = this.preparePayload(this.dataset);
      const response = await ApiService.post('/feature_importance/', payload);
    },
    async loadLocations() {
      try {
        const model = this.modelInState;
        const tableName = `model_table_${model.model_code}`;
        const q = `SELECT distinct("Location") AS "Location" from ${tableName} ORDER BY "Location" ASC`;
        const response = await ApiService.post('/raw_sql/', { q });
        this.locations = response.data;
      } catch (error) {
        //
      }
    },
    async setScores() {
      const model = this.modelInState;

      const payload = {
        model_code: model.model_code,
      };
      if (this.location) {
        payload.location = this.location;
      }
      try {
        const response = await ApiService.post('/scores/', payload);
        console.log('response', response.data);
        this.scores = response.data;
      } catch (error) {
        this.scores = null;
      }
    },
    async build(dataset) {
      const payload = this.preparePayload(dataset);
      const response = await ApiService.post('/train/', payload);
      console.log('response', response.data);
    },
    async download() {
      const payload = this.preparePayload(this.dataset);
      downloadURI(`/download/${payload.model_code}`, 'export');
      // await ApiService.post('/download/', payload);
      // console.log('response', response.data);
    },
    async getUnchangedModel() {
      const model = this.modelInState;
      const query = createTrainingQuery(model);
      try {
        const params = {
          model_code: model.model_code,
        };
        const response = await ApiService.post('/model', params);
        const data = response.data;
        if (data && data.length) {
          const ui = JSON.parse(data[0].ui_json);
          const changeId = this.getChangeId(this.dataset);
          if (ui.change_id && ui.change_id === changeId) {
            return data[0];
          }
        }
      } catch (error) {}
      return null;
    },
    setShowCluster() {
      const model = this.modelInState;
      this.showCluster = false;
      const yS = model.datasets.filter(
        (item) => item.axis === 'y' && item.table_name,
      ).length;
      const xS = model.datasets.filter(
        (item) => item.axis === 'x' && item.table_name,
      ).length;
      if (yS && xS && this.dataset.axis === 'x') {
        this.showCluster = true;
      }
    },
  },
};
</script>
