<template>
  <v-stepper
    v-if="model"
    v-model="step"
    :vertical="false"
  >
    <v-stepper-header>
      <template v-for="(dataset, index) in model.datasets">
        <v-stepper-step
          :key="dataset.code + index"
          :complete="step > dataset.order"
          :step="dataset.order"
          @click="goToThisStep(dataset.order)"
        >
          {{ dataset.title }}
          <small>{{ dataset.subtitle }}</small>
        </v-stepper-step>
      </template>
    </v-stepper-header>
    <v-stepper-items>
      <template v-for="(dataset, index) in model.datasets">
        <v-stepper-content
          :key="dataset.code + index + 'content'"
          :step="dataset.order"
        >
          <v-row style="min-height: 700px">
            <v-col sm="3">
              <model-selector
                v-if="
                  activeDataset &&
                    activeDataset.code === dataset.code &&
                    model &&
                    dataset.axis === 'build'
                "
                :input="{ dataset, index, model }"
                @next="nextDataset"
                @updated="updated"
                @build="build"
              />
              <dataset-selector
                v-if="
                  activeDataset &&
                    activeDataset.code === dataset.code &&
                    dataset.axis !== 'build'
                "
                :input="{ dataset, index }"
                @next="nextDataset"
                @updated="updated"
              />
            </v-col>
            <v-col
              v-if="activeDataset"
              sm="9"
            >
              <chart
                v-if="activeDataset && activeDataset.code === dataset.code"
                :key="activeDataset.uiKey + activeDataset.table_name"
                :dataset="dataset"
              />
            </v-col>
          </v-row>
        </v-stepper-content>
      </template>
    </v-stepper-items>
  </v-stepper>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
// eslint-disable-next-line import/no-unresolved
import { createModel } from '@/lib/model';
import Chart from './chart.vue';

import { createTrainingQuery } from '../../lib/query-creator';
import ApiService from '../../services/api.service';

const modelNamespace = createNamespacedHelpers('model');

export default {
  components: {
    // DatasetStepper: () => import('@/components/model/stepper.vue'),
    DatasetSelector: () => import('@/components/model/selector.vue'),
    ModelSelector: () => import('@/components/model/model-selector.vue'),
    Chart,
  },
  data() {
    return {
      step: 1,
      model: null,
    };
  },
  computed: {
    ...modelNamespace.mapState({
      activeDataset: (state) => state.dataset,
      modelInState: (state) => state.model,
      // step: (state) => state.step,
    }),
  },
  mounted() {
    // this.createModel(1);
    this.model = this.modelInState;
    this.goToStep(1);
  },
  methods: {
    ...modelNamespace.mapActions(['aCreateModel']),
    ...modelNamespace.mapMutations(['mSetDataset']),
    async build(dataset) {
      console.log('build', dataset);
      this.mSetDataset(dataset);
      // const model = this.modelInState;
      // const query = createTrainingQuery(this.modelInState);
      // model.sql = query.sql;

      // const payload = {
      //   model_architecture: dataset.algorithm,
      //   independent_variable_names_selected_by_user: [
      //     'x1',
      //     'day_of_year',
      //     'Lat best',
      //     'Long best',
      //   ],
      //   model_name: dataset.name,
      //   type: dataset.algorithm,
      //   description: model.description,
      //   model_code: model.model_code,
      //   start_date: dataset.start_date,
      //   end_date: dataset.end_date,
      //   start_test_date: dataset.start_test_date,
      //   sql: query.sql,
      //   ui_json: JSON.stringify(model),
      // };

      // const response = await ApiService.post('/train/', payload);
      // console.log('response', response.data);
    },
    createModel(step) {
      // this.aCreateModel();
      this.model = this.modelInState;
      this.goToStep(step);
    },
    goToThisStep(step) {
      this.step = step;
      const that = this;
      that.setActiveDataset({ ...this.model.datasets[this.step - 1] });
    },
    goToStep(step) {
      this.step = step;
      this.setActiveDataset(this.model.datasets[this.step - 1]);
    },
    nextStep(step) {
      this.step = step + 1;
      this.setActiveDataset(this.model.datasets[step - 1]);
      this.setActiveDataset(this.model.datasets[this.step - 1]);
    },
    setActiveDataset(dataset) {
      // this.activeDataset = { ...dataset };
      // alert(dataset.axis);
      this.mSetDataset(dataset);
    },
    updated(dataset) {
      this.setActiveDataset(dataset);
    },
    nextDataset(dataset) {
      this.nextStep(dataset.order);
      // this.datasets.forEach(item => {
      // });
    },
  },
};
</script>
